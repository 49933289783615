body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Snowing effect */
#snowflakeContainer {
  position: absolute;
  left: 0px;
  top: 0px;
}

.snowbody {
background-color:red;
/* background-image: url('images/Christmas_background_seamless_red.png'); */
background-repeat: no-repeat;
background-attachment: fixed;
background-size: cover;
}

.login {
color:#04a30b;
}

.snowflake {
  padding-left: 15px;
  font-family: Cambria, Georgia, serif;
  font-size: 14px;
  line-height: 24px;
  position: fixed;
  color: #FFFFFF;
  user-select: none;
  z-index: 1000;
}
.snowflake:hover {
  cursor: default;
}
