
.button-green {
    background: #34A65F;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#34A65F), to(#335d30));
    background: -moz-linear-gradient(#34A65F, #335d30);
    background: linear-gradient(#34A65F, #335d30);
    border: solid 1px #1b3013;
    border-bottom: solid 3px #0a1608;
    box-shadow: inset 0 0 0 1px #53864f;
    color: #142413;
    text-shadow: 0 1px 0 #4b7b47; 
}