button {
    margin-left: 10px;
}

input {
    float: center;
}

.react-bootstrap-table {
    table-layout: fixed;
    word-break: break-all;
}

.sc-bdVaJa {
    cursor:pointer;
}

.selection-cell {
    background-color: rgb(115, 214, 118) !important;
}

th {
    background-color: white;
}

/* .modal-content {
    background-color: green;
} */